@import "../../assets/css/mainColors.css";

.not-found-container {
    height: 70vh;
}

h1 {
    font-size: 8rem;
    margin-bottom: 0;
}

h2 {
    font-size: 3rem;
    margin-bottom: 2rem;
}

.lead {
    font-size: 1.5rem;
    color: var(--blueSMD);
}

.display-1 {
    animation: bounce 1.5s infinite;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}